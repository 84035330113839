.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  margin: 6px 0;
}

.input-container input {
  border-radius: 6px;
  padding: 10px 16px;
  margin: 4px 0;
  border: 1px solid #c6c6c6;
}

.input-label {
  font-weight: 400;
}

.input-error {
  font-weight: 400;
  font-size: 0.8em;
  color: red;
}
