.playlist-add-container {
  .playlist-add-button {
    // margin: 20px;
    border: 1px solid #EF8833;
    border-radius: 4px;
    width: 200px;
    height: 250px;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #00000010;
      transition: 50ms ease-in-out;
    }

    .playlist-add-icon {
      width: 50px;
      height: 50px;
      color: #EF8833;
      margin: 10px;
    }
  }
}