.theme-item-container {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px;

  .theme-item-title {
    width: 50%;
  }
}

.theme-item-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}