.playlist-item-container {
  border: 1px solid #EF8833;
  border-radius: 4px;
  width: 200px;
  height: 250px;
  margin: 20px 0px;

  cursor: pointer;

  &:hover {
    background-color: #00000010;
    transition: 50ms ease-in-out;
  }

  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;

  .theme-item-actions-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .playlist-item-cover {
    width: 100%;
    height: 80%;
    object-fit: cover;
    flex: 1;
  }

  .playlist-item-icon {
    width: 20px;
    height: 20px;
  }

  .playlist-item-details-container {
    display: flex;
    height: 15%;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }

  .playlist-item-title {
    padding: 0 10px;
  }


  .theme-item-title {
    font-size: 20px;
  }
}