.button {
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  padding: 10px 0;
  border-radius: 90px;
  margin: 6px 0;
  font-family: "Raleway";
  font-weight: 600;
  cursor: pointer;
}

.button.primary {
  background-color: #ef8833;
  border-width: 0;
  color: white;

  &:hover {
    background-color: #00A1E4;
  }
}

.button.secondary {
}
